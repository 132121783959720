import React from "react";
import Heading from "./Heading";
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

const Solution: React.FunctionComponent<{
  title: string;
  label: string;
  children: React.ReactNode;
  frontmatter: {
    imageSolution?: string
    imageSolution_alt: string
  }
}> = ({ children, frontmatter, label, title }) => {

    let imgSolution = getImage(frontmatter.imageSolution)
  return (


      <div className="grid grid-cols-1 lg:grid-cols-15 mb-12 lg:mb-20 gap-y-4">
        <div className="hidden lg:block row-start-1 col-start-3 col-end-7 z-10"/>
        <div className="hidden lg:block row-start-1 col-start-7 col-span-full bg-yellow lg:rounded-l-lg"/>
        <div className="container row-start-1 col-span-full grid grid-cols-1 lg:grid-cols-2 items-start lg:gap-x-16 gap-y-4 my-0 lg:my-24">
            {imgSolution
              ? <figure className="overflow-hidden border-4 border-white rounded-xl shadow-2 mx-4 lg:mx-0 transition-image z-1  lg:max-w-[600px] lg:justify-self-end" data-aos="custom">
                  <GatsbyImage
                    image={imgSolution}
                    alt={frontmatter.imageSolution_alt}
                    className="h-48 lg:h-72	object-cover w-full"
                  />
                  <span className="transition-layer"/>
                </figure>
              : <div></div>
          }
          <div className="bg-yellow lg:bg-transparent px-4 py-6 lg:p-0">
            <Heading black>{label}</Heading>
            <span className="block h2 tracking-tight mb-4">{title}</span>
            {children}
          </div>
        </div>
      </div>
  );
};
export default Solution;
