import React from "react";
import Facebook from "../assets/svg/facebook.svg";
import Instagram from "../assets/svg/instagram.svg";
import Linkedin from "../assets/svg/linkedin.svg";

interface Props {
  styleClass?: string;
}

const SocialLinks = ({ styleClass = "self-center" }: Props) => {
  return (
    <ul className={"social flex space-x-5 col-span-6 " + styleClass}>
       <li className="cursor-pointer rounded-full p-2 bg-gray-600 hover:bg-white">
        <a href="https://fr.linkedin.com/company/e-reflex" target="_blank" rel="noopener">
          <Linkedin />
        </a>
      </li>
    </ul>
  );
};
export default SocialLinks;
