import React, {useEffect, useState, useRef} from "react";
import { Link } from "gatsby";
import AtomIcon from "assets/svg/atom-data.svg";
import Logo from "assets/svg/logo.svg";
import MailIcon from "assets/svg/mail-icon.svg";
import classNames from "classnames/bind";
import { isMobile } from "react-device-detect";
import ArrowRight from "assets/svg/arrow-right.svg";

interface Props {
  onToggle: () => void;
  isOpen: boolean;
  location?: Location
}

const HeaderData = ({ isOpen, onToggle, location }: Props) => {
  const refHeader = useRef(null);

  const [scroll, setScroll] = useState<boolean>(false);

  const handleScroll = () => {
    setScroll(window.scrollY > 20);
  };

  useEffect(()=>{
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  },[scroll])

  const isHomePage = location?.pathname === '/'

  return (
    <header
      ref={refHeader}
      id="js-header"
      data-open={isOpen}
      className={classNames(
        "fixed pl-4 bg-transparent lg:pl-[100px] top-0 flex items-center justify-center md:justify-end w-full left-0 lg:pr-4 h-16 lg:h-20 z-10 transition-all duration-100 ease-ease bg-black bg-opacity-100 md:bg-opacity-0", {
        "shadow bg-black": isMobile,
        "bg-transparent": !isMobile,
        })
      }
    >
      {isOpen ? (
        <span className="text-label2 text-white font-sporting block lg:hidden">
        MENU
      </span>
      ) : (
        <Link to="/data">
          <div className="block md:hidden flex items-center">
            <Logo className="w-24 mr-2.5"/>
            <p
              className="rounded-5 border border-data-500 inline-flex px-2 py-2 items-center gap-1 uppercase">
              <AtomIcon className="w-3 h-3 shrink-0" />
              <span className="text-data-500 font-bold text-caption2">Data</span>
            </p>
          </div>
        </Link>
      )}
      <div className="flex">
        <a href="https://ddhaxm19y8k.typeform.com/to/PFynbfXF" target="_blank">
          <button className="hidden md:inline-flex items-center h-btn-data rounded-full bg-data-500 pl-5 pr-25px mr-2.5">
            <ArrowRight className="w-6 h-auto mr-2.5" />
            <span className="font-display font-bold">Évaluez votre maturité DATA</span>
          </button>
        </a>
        <a href="https://vingtcinq.io/contact" target="_blank">
          <button className="inline-flex items-center justify-center w-55px h-55px rounded-full bg-white duration-300 hover:shadow-green absolute md:top-0 top-1/2 right-0 mr-4 md:mr-0 shadow md:shadow-none md:relative">
            <MailIcon />
          </button>
        </a>
      </div>
    </header>
  )
};

export default HeaderData;
