import classNames from "classnames";
import React, { ElementType, ReactNode } from "react";

type Props<Tag extends keyof JSX.IntrinsicElements> = {
  tag?: ElementType | keyof JSX.IntrinsicElements;
  children?: ReactNode;
  variant: "primary" | "secondary" | "naked" | "light";
  disabled?: boolean;
  size?: "sm" | "md" | "lg";
  className?: string;
  onClick: Function;
} & JSX.IntrinsicElements[Tag];

const Button = ({
  tag: Tag = "button",
  size = "lg",
  variant = "primary",
  className = "",
  disabled,
  onClick = () => {},
  children,
  ...props
}: Props<any>) => (
  <Tag
    className={classNames(
      `btn ${variant} ${size} disabled:opacity-75 select-none`,
      className
    )}
    onClick={() => onClick()}
    disabled={disabled}
    {...props}
  >
    {children}
  </Tag>
);

export default Button;
