import React from "react";

const Achievements: React.FunctionComponent<{
  label: string;
  achievements: any;
}> = ({label,achievements }) => {
  if (!achievements) {
    return null;
  }
  return (
    <div className="container mb-16 lg:mb-32 gap-y-6">
      <div className="lg:row-start-1 lg:col-start-3 col-end-14">
        <p className="caption2 uppercase mb-5 3xl:text-h3">{label}</p>
        <ul className="flex flex-col gap-y-5 project-list">
          {
            achievements.map((item:string, index:number) => (
              <li key={index} className="py-5 px-3 border border-gray-100 rounded-xl h3">
                <span
                  data-aos="custom"
                  className="translate-right"
                  style={{transitionDelay: `${0.2 + (index * 0.1) }s`}}
                >{item}</span>
              </li>
            ))
          }
        </ul>
      </div>
    </div>
  );
};
export default Achievements;
