import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const goal = 'objectif';
export const context = 'contexte';
export const solution = 'solution';
export const testimonial = 'temoignage';
export const achievements = 'résultats';
export const _frontmatter = {};
const layoutProps = {
  goal,
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      