import ArrowRight from "assets/svg/arrow-right.svg";
import Logo from "assets/svg/logo.svg";
import classNames from "classnames";
import BaseButton from "components/button/BaseButton";
import Links from "components/header/links";
import { Link } from "gatsby";
import React, { useEffect, useRef, useState } from "react";
import { isMobile } from "react-device-detect";

interface Props {
  onToggle: () => void;
  isOpen: boolean;
  location?: Location;
}

const Header = ({ isOpen, onToggle, location }: Props) => {
  const refHeader = useRef(null);

  const [scroll, setScroll] = useState<boolean>(false);

  const handleScroll = () => {
    setScroll(window.scrollY > 20);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [scroll]);

  const isHomePage = location?.pathname === "/";

  const handleClickPlausible = () => {
    if ("plausible" in window) {
      window.plausible("start-project");
    }
  };

  return (
    <header
      ref={refHeader}
      id="js-header"
      data-open={isOpen}
      className={classNames(
        "fixed pl-4 lg:pl-[100px] top-0 flex items-center w-full left-0 lg:pr-4 h-16 lg:h-20 z-10 transition-all duration-100 ease-ease bg-black lg:bg-white",
        {
          "shadow bg-white": !isMobile && scroll,
          "lg:bg-transparent-100": isHomePage && !scroll,
          "bg-white": isHomePage && scroll && !isMobile,
        }
      )}
    >
      {isOpen ? (
        <span className="text-label2 text-white font-sporting block lg:hidden">
          MENU
        </span>
      ) : (
        <Link to="/">
          <Logo className="w-24 block lg:hidden " />
        </Link>
      )}
      <nav className="hidden w-full lg:block md:w-auto">
        <Links styleClass="flex flex-col mt-4 md:flex-row md:space-x-10 md:mt-0" />
      </nav>
      <nav className="hidden lg:flex ml-auto">
        <BaseButton
          onClick={() => handleClickPlausible()}
          tag={Link}
          variant="data"
          to="/data"
          size="md"
          className="arrow-left-in mr-3"
        >
          <ArrowRight />
          Notre offre Data
        </BaseButton>
        <BaseButton
          onClick={() => handleClickPlausible()}
          tag={Link}
          to="/contact?type=projet"
          size="md"
          className="arrow-left-in"
        >
          <ArrowRight />
          Démarrer un projet
        </BaseButton>
      </nav>
      <button
        onClick={onToggle}
        type="button"
        className="block lg:hidden burger-button ml-auto w-16 h-16 bg-gray-800 cursor-default"
        title="Menu"
      >
        <span className="hidden">Toggle menu</span>
        <span className="burger-bar burger-bar--1 bg-white" />
        <span className="burger-bar burger-bar--2 bg-white" />
        <span className="burger-bar burger-bar--3 bg-white" />
      </button>
    </header>
  );
};

export default Header;
