import React from "react";
import Heading from "./Heading";

const Goal: React.FunctionComponent<{
  title: string;
  label: string;
  children?: React.ReactNode;
}> = ({ children, label, title }) => {
  return (
    <div className="container">
      <div className="mb-10 lg:mb-20">
        <p className="caption2 uppercase text-gray-600 mb-5 3xl:text-h3">{label}</p>
        <span className="block h2 text-gray-600 tracking-tight mb-4" dangerouslySetInnerHTML={{ __html: title }} />
        <p>
          {children}
        </p>
      </div>
    </div>
  );
};
export default Goal;
