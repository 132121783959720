import React from "react";
import Heading from "./Heading";

const Blockquote: React.FunctionComponent<{
  label: string;
  children: React.ReactNode;
  frontmatter: {
    testimonialName: string;
    testimonialRole: string;
  };
}> = ({ children, frontmatter, label }) => {
  return (
    <div className="container mb-16 lg:mb-28 lg:px-36">
      <Heading>{label}</Heading>
      <figure>
        <blockquote className="relative">
          <p className="quote1 mb-4">{children}</p>
        </blockquote>
        {(frontmatter.testimonialName || frontmatter.testimonialRole) && (
          <figcaption>
            <cite className="not-italic">
              {frontmatter.testimonialName && (
                <p className="label1 mb-1">{frontmatter.testimonialName}</p>
              )}
              {frontmatter.testimonialRole && (
                <p className="text-gray-600 text-b3">
                  {frontmatter.testimonialRole}
                </p>
              )}
            </cite>
          </figcaption>
        )}
      </figure>
    </div>
  );
};
export default Blockquote;
