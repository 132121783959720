import React from "react";
import Links from "components/header/links";
import Social from "components/social";
import BaseButton from "../button/BaseButton";
import { Link } from "gatsby";
import classNames from "classnames/bind";

interface Props {
  isOpen: boolean;
  onToggle: () => void;
}

const Sidebar = ({ isOpen, onToggle }: Props) => {
  return (
    <aside
      className={classNames(
        "sidebar fixed w-full h-full z-50 mt-16 bg-gray-800 transition-all duration-03 ease-linear", {
          "is-open visible translate-x-0": isOpen ,
          "invisible translate-x-full": !isOpen,
        })
      }
    >
      <div className="sidebar-container flex flex-col px-6 py-7 overflow-y-auto">
        <Links
          toggle={onToggle}
          styleItems="font-medium font-sporting text-[1.8rem] text-white cursor-default"
          styleClass="flex flex-col"
        />
        <Social styleClass="self-start"/>
        <BaseButton
          onClick={onToggle}
          tag={Link}
          to="/contact"
          target="_blank"
          rel="noopener"
          role="button"
          className="flex lg:hidden mt-8 lg:mt-auto delay-05 text-center"
        >
          Démarrer un projet
        </BaseButton>
      </div>
    </aside>
  );
};

export default Sidebar;
