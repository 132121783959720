import React from "react";

const titleColumn: React.FunctionComponent<{
  black?: boolean;
  children: React.ReactNode;
}> = ({ children, black }) => {
  return (
    <p
      className={
        "caption2 uppercase mb-4 3xl:text-h3 " + (black ? undefined : "text-gray-600")
      }
    >
      {children}
    </p>
  );
};

export default titleColumn
