import './src/styles/global.css'
import { wrapMDX } from './root-mdx'
import React from 'react'
import Layout from './src/components/layout'
import LayoutData from './src/components/layoutData'

export const wrapRootElement = wrapMDX

export function wrapPageElement({ element, props }) {
  // props provide same data to Layout as Page element will get
  // including location, data, etc - you don't need to pass it
  return props.pageContext.layout === 'data' ? <LayoutData {...props}>{element}</LayoutData> : <Layout {...props}>{element}</Layout>
}

export function shouldUpdateScroll ({
  routerProps: { location },
  getSavedScrollPosition
}) {
  return false
}
