import * as Label from "../../../../src/content/project-vars.mdx";
import { Link } from 'gatsby';
import { Solution, Goal, Achievements } from "../../../../src/components/complete";
import * as React from 'react';
export default {
  Label,
  Link,
  Solution,
  Goal,
  Achievements,
  React
};