export default [
  {
    index: "01.",
    to: "/la-fabrique",
    name: "La Fabrique",
  },
  {
    index: "02.",
    to: "/notre-approche",
    name: "Notre approche",
  },
  {
    index: "03.",
    to: "/notre-offre",
    name: "Notre offre",
  },
  {
    index: "04.",
    to: "/projets",
    name: "Projets",
  },
  {
    index: "05.",
    to: "/carrieres",
    name: "Carrières",
  },
];
