import React, { useState, useEffect } from "react";
import { CSSTransition, SwitchTransition } from "react-transition-group";
import { useStaticQuery, graphql, Link } from "gatsby";
import Sidebar from "./header/sidebar";
import Header from "./header/header";
import Social from "./social";
import ArrowRight from "../assets/svg/arrow-right.svg";
import LogoVertical from "../assets/svg/logo-vertical.svg";
import listLinks from "../constants/links";
import classNames from "classnames/bind";
import { ParallaxProvider } from "react-scroll-parallax";
import AtomIcon from "assets/svg/atom-data.svg";
import VqLogo from "assets/svg/vingtcinq-logo.svg";
import AOS from 'aos';
import 'aos/dist/aos.css';
import {  animateScroll as scroll } from "react-scroll";
import HeaderData from "./header/header-data";

interface LayoutDataProps {
    children: React.ReactNode;
    location?: Location;
}

interface Props {
    to: string;
    children?: React.ReactNode;
}

const LayoutData = ({ children, location }: LayoutDataProps) => {
    const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  const [isOpen, setIsOpen] = useState<boolean>(false);
  useEffect(() => {
    AOS.init({
      // Global settings:
      debounceDelay: 50, // the delay on debounce used while resizing window (advanced)
      once: true, // whether animation should happen only once - while scrolling down
      mirror: false, // whether elements should animate out while scrolling past them
    });
  }, []);

  useEffect(() => {
      if (isOpen) {
          document.body.style.overflow = "hidden";
      } else {
          document.body.style.overflow = "unset";
      }
  }, [isOpen]);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const scrollTo = () => {
    if (!isHomePage) return false
    scroll.scrollToTop()
  }

  const ListLink = ({ to, children }: Props) => (
    <li className="footer-link">
      <ArrowRight />
      <Link to={to}>{children}</Link>
    </li>
  );

  const isHomePage = location?.pathname === '/'

  const handleClickPlausible = () => {
    if ("plausible" in window) {
      window.plausible("contact-us");
    }
  }



  return (
    <div className="flex">
      <aside className="sticky top-0 hidden lg:flex h-screen flex-col bg-gray-900 grow shrink-0 basis-full max-w-[66px] justify-center lg:px-5 hover:bg-gray-800 transition duration-03 ease-out z-20">
        <LogoVertical />
        <Link to={`/`} onClick={scrollTo} className="stretched-link" />
      </aside>
      <div className="relative flex-auto flex flex-col w-full lg:w-main">
        <Sidebar isOpen={isOpen} onToggle={handleToggle} />
        <HeaderData location={location} isOpen={isOpen} onToggle={handleToggle} />
        <main className={classNames("sm:pl-18 flex-1 z-0 mt-16 md:mt-0")}>
          <SwitchTransition mode={"out-in"}>
            <CSSTransition
              key={location?.pathname}
              classNames='page-fade'
              timeout={200}
              onEnter={() => {
                  window.scrollTo(0, 0)
                }
              }
            >
              <ParallaxProvider>
                <div id="wrapper">
                  {children}
                </div>
              </ParallaxProvider>
            </CSSTransition>
          </SwitchTransition>
        </main>
        <footer style={{ backgroundImage: "url('/data/footer-background-data.png')" }} className="hero-data-background bg-black bg-cover bg-no-repeat bg-center text-center mt-auto p-6 lg:px-10 lg:pt-24 lg:pb-16 flex lg:flex-row flex-col ">
          <div className="w-full">
            <div>
            <VqLogo className="mx-auto mb-4" />
            <p
              data-aos="custom"
              className="shadow-green-large mb-3 lg:mb-10 fade rounded-5 border border-data-500 inline-flex px-2 py-2 items-center gap-1 uppercase">
              <AtomIcon className="w-3 h-3 shrink-0" />
              <span className="text-data-500 font-bold text-caption2">Data</span>
            </p>
            </div>
            <a href="https://ddhaxm19y8k.typeform.com/to/PFynbfXF" target="_blank">
              <button className="inline-flex items-center h-btn-data mb-50px rounded-full bg-data-500 pl-5 pr-25px">
                <ArrowRight className="w-6 h-auto mr-2.5" />
                <span className="font-display font-bold">Évaluez votre maturité DATA</span>
              </button>
            </a>
            <div className="text-white">
              <div>
                <address className="text-b2 not-italic">
                  78 Rue d'Hauteville - 75010 Paris
                </address>
                <a className="text-b2 not-italic my-1 block" href="tel:+33148070242">Tél. +33 01 48 07 02 42</a>
                <Link
                  to="/contact"
                  onClick={() => handleClickPlausible()}
                  className="block text-b2 mx-auto text-data-500 w-fit relative"
                >
                  Contactez-nous
                </Link>
              </div>
            </div>
            <div className="mx-auto">
              <Social styleClass="justify-center my-50px"/>
            </div>
            <nav className="flex flex-col justify-center md:flex-row gap-x-5 gap-y-2 col-span-12 lg:col-start-8 lg:col-end-13 self-center">
              <Link to="/" className="text-b4 text-gray-600">
                vingtcinq.io
              </Link>
              <span className="text-b4 text-gray-600">
                © {new Date().getFullYear()} La Fabrique VingtCinq
              </span>
              <Link to="/mentions-legales" className="text-b4 text-gray-600">
                Mentions Légales
              </Link>
              <Link to="/politique-de-confidentialite" className="text-b4 text-gray-600">
                Politique de confidentialité
              </Link>
            </nav>
          </div>
        </footer>
      </div>
    </div>
  );
};

export default LayoutData;
