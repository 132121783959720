import classNames from "classnames";
import { Link } from "gatsby";
import React from "react";
import listLinks from "../../constants/links";

interface Props {
  to: string;
  children?: React.ReactNode;
  styleItems?: string;
  number: string;
  toggle?: () => void;
}

interface linksProps {
  styleClass: string;
  styleItems?: string;
  toggle?: () => void;
}

const ListLink = ({
  to,
  children,
  styleItems,
  number,
  toggle = () => {},
}: Props) => (
  <li
    onClick={toggle}
    className="border-b lg:border-none border-gray-700 pb-4 lg:pb-0 mb-8 lg:mb-0 relative"
  >
    <span className="block lg:hidden text-label2 text-gray-600 font-sporting mb-2">
      {number}
    </span>
    <Link
      className={classNames("stretched-link select-none", styleItems)}
      to={to}
      activeClassName="lg:text-gray-900"
      partiallyActive={true}
    >
      {children}
    </Link>
  </li>
);

const Links = ({
  toggle,
  styleClass,
  styleItems = "text-menu font-medium text-gray-600 hover:text-black",
}: linksProps) => {
  return (
    <ul className={styleClass}>
      {listLinks.map(
        (link: { name: string; index: string; to: string }, index: number) => (
          <ListLink
            toggle={toggle}
            key={index}
            styleItems={styleItems}
            number={link.index}
            to={link.to}
          >
            {link.name}
          </ListLink>
        )
      )}
    </ul>
  );
};

export default Links;
