import React from 'react'
import { MDXProvider } from '@mdx-js/react'
import { Blockquote } from './src/components/complete'

const components = {
    blockquote: Blockquote,
}
export const wrapMDX = ({ element }) => {
    return <MDXProvider components={components}>{element}</MDXProvider>
}
